import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import {
  Button,
  ExportTableParams,
  IconButton,
  IOnFetchArguments,
  Loader,
  Modal,
  styled,
  Table,
  TCommonParams,
  Tooltip,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultPageSize, pageSizes } from 'consts';
import { ROUTES } from 'routes';
import {
  getCorporateActions,
  ExpandedCorpAction,
  getColumnsForCorporateActions,
  getCorporateActionsExport,
  EventFetchedColumn,
} from 'services/CorporateActions';
import { EventTypes } from 'services/types';
import { calculateCountOfPages, getPayloadFromUrl } from 'utils';

import classes from './CorporateActions.module.css';
import { ImportFileIcon } from './components';
import { defaultActionTitle, displayedColumnsForDividends } from './consts';
import { getColumns } from './getColumns';
import { useFiles, useSelectedRows } from './hooks';
import { ICorpActions } from './types';

export const ActionsDiv = styled('div')(() => ({
  padding: '6px 0 6px 8px',
}));
export const CorporateActionsTable: FC<{
  tableId: string;
  selectedTab: string;
  eventTypes: EventTypes;
}> = ({ tableId, selectedTab, eventTypes }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { search } = useLocation();

  const [actionColumns, setActionColumns] = useState<EventFetchedColumn[]>([]);
  const tableKey = useMemo(() => `${tableId}-view-params`, [tableId]);

  const urlParams = useMemo(() => getPayloadFromUrl(search), [search]);
  const getActions = useCallback(
    ({ params }: IOnFetchArguments) =>
      getCorporateActions({
        ...params,
        ca_type: params.ca_type ?? eventTypes[selectedTab],
      }),
    [search, selectedTab, eventTypes],
  );

  const configs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getActions },
      sorting: {
        getDefaultSorting: () => {
          return [{ id: 'id', desc: true }];
        },
      },
      saveViewParamsAfterLeave: true,
      pagination: {
        getDefaultPagination: () => ({ limit: defaultPageSize, skip: 0 }),
      },
    }),
    [getActions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    sorting,
    fetchData,
  } = useTableData<ICorpActions>(configs);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
    const oldParams = JSON.parse(localStorage.getItem(tableKey) ?? '');
    if (urlParams.task_id) {
      localStorage.setItem(
        tableKey,
        JSON.stringify({
          ...oldParams,
          filters: { task_id: urlParams.task_id },
        }),
      );
      history.push({
        pathname: path,
        search: `?task_id=${urlParams.task_id}`,
      });
      resetFilters([]);
      setFilter('task_id', urlParams.task_id);
    }
  }, []);

  const handleOpenDetails = useCallback((action: ExpandedCorpAction) => {
    history.push(`${ROUTES.CORPORATE_ACTIONS}/${action.id}`);
  }, []);

  const loadColumns = async () => {
    const response = await getColumnsForCorporateActions();
    setActionColumns(response);
  };

  useEffect(() => {
    loadColumns();
  }, []);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const handleOpenNewCA = useCallback(() => {
    history.push(`${ROUTES.NEW_CORPORATE_ACTION}`);
  }, []);

  const {
    selectedRows,
    setSelectedRows,
    handleSelectRow,
    selectedIds,
    handleUnCheckAllOnPage,
    handleCheckAllOnPage,
    rollbackIsLoading,
    deleteIsLoading,
    executeIsLoading,
    reRunIsLoading,
    rollbackIsDisabled,
    rollbackButtonTitle,
    executeIsDisabled,
    executeButtonTitle,
    deleteIsDisabled,
    deleteButtonTitle,
    rerunIsDisabled,
    rerunButtonTitle,
    actionType,
    handleConfirmAction,
    setActionType,
  } = useSelectedRows(fetchData, data?.actions ?? []);

  useEffect(() => {
    setSelectedRows([]);
  }, [filters]);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        columnsList: actionColumns,
        rows: data?.actions ?? [],
        onUnCheckAllRowsOnPage: handleUnCheckAllOnPage,
        onCheckAllRowsOnPage: handleCheckAllOnPage,
        selectedRowsIds: selectedIds,
        onCheckRow: handleSelectRow,
        eventTypes: eventTypes ? eventTypes[selectedTab] : [],
      }),
    [
      setFilter,
      removeFilter,
      actionColumns,
      data,
      selectedIds,
      eventTypes,
      selectedTab,
    ],
  );

  const exportTableParams = useMemo(
    () => ({
      type: 'server',
      onFetch: (params: TCommonParams) => {
        return getCorporateActionsExport({
          ...params,
          ca_type: params.ca_type ?? eventTypes[selectedTab],
        });
      },
      excludedColumns: ['checking'],
    }),
    [selectedTab, eventTypes],
  );

  const {
    fileIsLoading,
    handleUploadFile,
    blobFileIsExist,
    handleCloseConfirmModal,
    handleConfirmUploadFile,
    inputFileKey,
    fileName,
  } = useFiles(fetchData);

  const additionalActions = useMemo(
    () => [
      {
        title: 'Add action',
        component: (
          <IconButton
            iconColor="action"
            iconName="AddIcon"
            label="Add action"
            onClick={handleOpenNewCA}
          />
        ),
      },
      {
        children: fileIsLoading ? (
          <Loader />
        ) : (
          <ImportFileIcon
            key={inputFileKey}
            title="Bulk import"
            onUploadFile={handleUploadFile}
          />
        ),
        onClick: () => {},
        title: 'Bulk import',
      },
    ],
    [fileIsLoading, handleUploadFile],
  );

  const actions = [
    rollbackIsLoading ? (
      <Loader />
    ) : (
      <span className={classes.ActionItem}>
        <Tooltip
          title={
            !selectedRows.length ? defaultActionTitle : rollbackButtonTitle
          }
        >
          <Button
            className={classes.ActionItem}
            size="small"
            color="primary"
            onClick={() => setActionType('rollback')}
            disabled={rollbackIsDisabled || !selectedRows.length}
          >
            Rollback
          </Button>
        </Tooltip>
      </span>
    ),
    executeIsLoading ? (
      <Loader />
    ) : (
      <span className={classes.ActionItem}>
        <Tooltip
          title={!selectedRows.length ? defaultActionTitle : executeButtonTitle}
        >
          <Button
            className={classes.ActionItem}
            size="small"
            color="primary"
            onClick={() => setActionType('execute')}
            disabled={executeIsDisabled || !selectedRows.length}
          >
            Execute
          </Button>
        </Tooltip>
      </span>
    ),
    deleteIsLoading ? (
      <Loader className={classes.ActionLoaderItem} />
    ) : (
      <span className={classes.ActionItem}>
        <Tooltip
          title={!selectedRows.length ? defaultActionTitle : deleteButtonTitle}
        >
          <Button
            className={classes.ActionItem}
            size="small"
            color="red"
            onClick={() => setActionType('delete')}
            disabled={deleteIsDisabled || !selectedRows.length}
          >
            Delete
          </Button>
        </Tooltip>
      </span>
    ),
    reRunIsLoading ? (
      <Loader className={classes.ActionLoaderItem} />
    ) : (
      <span className={classes.ActionItem}>
        <Tooltip
          title={!selectedRows.length ? defaultActionTitle : rerunButtonTitle}
        >
          <Button
            className={classes.ActionItem}
            size="small"
            color="secondary"
            onClick={() => setActionType('rerun')}
            disabled={rerunIsDisabled || !selectedRows.length}
          >
            Rerun
          </Button>
        </Tooltip>
      </span>
    ),
  ].map((item) => () => item);

  return (
    <>
      <Table
        tableId={tableId}
        title="Corporate Actions"
        customFiltersBottomComponent={
          <ActionsDiv className="d-flex ml-4">
            {actions.map((item, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={key}>{item()}</div>
            ))}
          </ActionsDiv>
        }
        columns={columns}
        data={data?.actions || []}
        filteringProps={filteringProps}
        isLoading={isLoading}
        saveColumnOrder
        isPinnedHeader
        hasFilters
        hasPagination
        showTableInfo
        displayedColumnKeys={[
          'checking',
          ...(selectedTab === 'Dividend'
            ? displayedColumnsForDividends
            : columns.map((item) => item.accessor)),
          'actions',
        ]}
        onSort={setSorting}
        defaultSortBy={sorting}
        exportTableParams={
          exportTableParams as ExportTableParams<ExpandedCorpAction>
        }
        saveViewParamsAfterLeave
        additionalActions={additionalActions}
        rowActions={{
          show: true,
          isEditedRow: () => false,
          additionalActions: [
            {
              label: <IconButton iconSize={24} iconName="EyeActiveIcon" />,
              onClick: (value) => {
                handleOpenDetails(value);
              },
              title: 'Show details',
            },

            {
              label: '',
              onClick: () => {},
              title: '',
            },
          ],
        }}
        manualSortBy
        isFlexLayout
        pageSizes={pageSizes}
        serverPaginationProps={{
          pageIndex: page,
          pageCount,
          pageSize: limit,
          total,
          setPage,
          setPageSize: setLimit,
        }}
      />
      <Modal
        isOpened={blobFileIsExist}
        title={`Import file "${fileName}".`}
        onClose={handleCloseConfirmModal}
        confirmButton={{
          confirmButtonName: 'Import',
          handleConfirm: handleConfirmUploadFile,
          confirmButtonIsDisabled:
            fileIsLoading || (!fileIsLoading && !blobFileIsExist),
        }}
      >
        <p>Are you sure?</p>
      </Modal>
      <Modal
        isOpened={!!actionType}
        title={`${actionType} events`}
        onClose={() => setActionType('')}
        confirmButton={{
          confirmButtonName: 'Confirm',
          handleConfirm: handleConfirmAction,
        }}
      >
        <p>Are you sure?</p>
      </Modal>
    </>
  );
};
